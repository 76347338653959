.background-image {
  background-image: url("../../public/assets/milky-way-bg.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 900px;
  padding: 20px;
  box-sizing: border-box;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.hero-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 300px;
  position: relative;
}
.hero-title {
  /* display: flex; */
  width: 100%;
  max-width: 1200px;
  /* justify-content: center;
  align-items: center; */
  gap: 8px;
  /* top: 190px; */
  /* position: absolute; */
}
.looking-for-an {
  padding: 40px 80px;
  border-radius: 186px;
  border: 8px solid #cb39c9;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(15px);
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.play-button-warpper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 50px;
}
.play-button {
  border-radius: 50px;
  border: 2px solid #ffabfe;
  background: #cb39c9;
  box-shadow: 8px 8px 72px 0px #cb39c9;
  padding: 18px 40px;
  gap: 8px;
}
.play-button:hover {
  border: 2px solid #e691e6;
  box-sizing: border-box;
}
.play-now {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.banner-container-three {
  margin: 100px auto;
  display: flex;
  align-items: center;
  max-width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  width: 1000px;
  box-sizing: border-box;
  gap: 24px;
}
.now-play {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000; /* Black outline */
}
.signup-txt {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    2px 2px 0 #000; /* Black outline */
}
/* Styles for mobile phones*/
@media only screen and (max-width: 600px) {
  .background-image {
    height: auto;
    background-size: cover;
  }
  .looking-for-an {
    border-radius: 24px;
    border: 4px solid #cb39c9;
    padding: 16px;
    gap: 8px;
    text-align: center;
    font-size: 18px;
    line-height: 26px;
  }
  .play-button-warpper {
    margin-bottom: 80px;
    margin-top: 80px;
  }
  .now-play {
    font-size: 18px;
  }
  .signup-txt {
    font-size: 18px;
  }
  .hero-wrapper {
    margin-top: 150px;
  }
}

/* Styles for Tablet */
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .background-image {
    height: 680px;
    background-size: contain;

  }
  .hero-wrapper {
    margin-top: 50px;
  }
  .looking-for-an {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    width: 664px;
    padding: 32px 60px;
  }
  .hero-title {
    width: auto;
    max-width: auto;
  }
  .banner-container-three {
    width: 550px;
  }
  .now-play {
    font-size: 24px;
  }
  .signup-txt {
    font-size: 24px;
  }
}
